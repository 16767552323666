htmx.defineExtension('client-side-templates', {
  transformResponse: function (text, _, elt) {
    const handlebarsTemplate = htmx.closest(elt, '[handlebars-template]');
    if (handlebarsTemplate) {
      const data = JSON.parse(text);
      const templateId = handlebarsTemplate.getAttribute('handlebars-template');
      const template = htmx.find('#' + templateId);

      const templateDelegate = Handlebars.compile(template.innerHTML);

      return templateDelegate(data);
    }

    return text;
  }
});
